<template>
  <div class="wrapper">
    <el-breadcrumb separator="/" class="el-breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">我要入园</el-breadcrumb-item>
      <el-breadcrumb-item>申请进度跟踪</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="concent">
      <section class="section">
        <div class="fl-c-b bb">
          <div class="fl-c">
            <div class="title">
              本次申请进度
              <el-select v-model="id">
                <el-option v-for="(item, index) in list" :key="index" :label="item.entryName" :value="`${item.projectId}`"></el-option>
              </el-select>
            </div>
            <!-- <div class="state-btn state-btn1">进行中</div>
            <div class="state-btn state-btn2">已通过</div>
            <div class="state-btn state-btn3">已驳回</div> -->
          </div>
          <div class="fl-box">
            <!-- <div class="tmp-btn" @click="show">材料模板</div>
            <div class="edit-btn">编辑信息</div> -->
          </div>
        </div>
        <div class="progress-box">
          <div class="fl-box progress-item" v-for="(v, index) in data" :key="index">
            <div class="lable">{{ v.processName }}</div>

            <div class="progress-content" :class="index == data.length - 1 ? 'no-line' : ''">
              <!-- <div :class="'color' + v.state">{{ state[v.state] }}</div> -->
              <div class="color4">{{ v.projectStatusName }}</div>
              <!-- <div :class="'color' + v.state">{{ state[v.state] }}</div> -->
              <div class="info-box">
                <div class="fl-c-b info-item" v-for="(item, i) in v.list" :key="i" :class="i != v.list.length - 1 ? 'bb' : ''">
                  <div class="name">{{ item.showName }}</div>
                  <div class="fl-c-b w-r">
                    <div>{{ item.time }}</div>
                    <div>{{ item.dealResult }}</div>
                    <template v-if="index == 5 && i == v.list.length - 1">
                      <el-link
                        v-if="v.contractStatus >= 3"
                        :href="baseURL + '/wztc/projectBasic/downloadContract?projectId=' + id"
                        type="primary"
                        :underline="false"
                        >查看合同</el-link
                      >
                      <el-button type="text" v-if="v.contractStatus < 5" @click="show(v.contractId)">反馈</el-button>
                      <el-button type="text" v-if="v.contractStatus < 5" @click="change(v.contractId)">确认</el-button>
                    </template>
                    <template v-else>
                      <div class=""></div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <el-dialog :close-on-click-modal="false" title="反馈" width="708px" :visible.sync="dialogVisable" :append-to-body="true">
      <el-form ref="saveForm" :model="saveData" :rules="saveRules" @submit.native.prevent label-width="100px" label-position="left">
        <el-row :gutter="28">
          <el-col>
            <el-form-item label="反馈内容" prop="remark">
              <el-input type="textarea" :rows="8" v-model="saveData.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisable = false">取消</el-button>
        <el-button type="primary" @click="save()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import config from '@/http/config.js';
export default {
  name: 'Progress',
  data() {
    return {
      baseURL: config.baseURL,
      dialogVisable: false,
      projectStatus: 0,
      data: [],
      id: '',
      saveData: {},
      saveInitData: {
        remark: '',
      },
      saveRules: {
        remark: [{ required: true, message: '请输入反馈内容', trigger: 'blur' }],
      },
      list: [],
    };
  },
  methods: {
    show(id) {
      this.dialogVisable = true;
      this.$nextTick(() => {
        this.$refs['saveForm'].resetFields();
        this.saveData = JSON.parse(JSON.stringify(this.saveInitData));
        this.saveData.id = id;
      });
    },
    save() {
      this.$refs['saveForm'].validate((valid) => {
        if (valid) {
          let p = {
            contractId: this.saveData.id,
            investContractStatus: 4,
            remark: this.saveData.remark,
          };
          this.$api.changeProjectContractInvestAgreeStatus(p).then((res) => {
            if (res.success) {
              this.$message.success({ message: '反馈成功', offset: 80 });
              this.getDate();
            } else {
              this.$message.error({ message: res.message, offset: 80 });
            }
            this.dialogVisable = false;
          });
        } else {
          return false;
        }
      });
    },
    async getId() {
      this.id = this.$route.params.id;

      if(this.type === 'safe'){
        let {result} = await this.$api.findSafeProjectList();
        console.log(`项目列表获取`,result)
        this.list = result
      }else{
        let {result} =await this.$api.findProjectList();
        this.list = result
      }
    

    
    },
    change(id) {
      let p = {
        contractId: id,
        investContractStatus: 5,
      };
      this.$api.changeProjectContractInvestAgreeStatus(p).then((res) => {
        if (res.success) {
          this.$message.success({ message: '操作成功', offset: 80 });
          this.getDate();
        } else {
          this.$message.error({ message: res.message, offset: 80 });
        }
        this.dialogVisable = false;
      });
    },
    async getDate() {
      let {success,result} = await  this.$api.getProjectProcessProjectId({ projectId: this.id },this.$route.query.type);
      if(success){
        this.projectStatus  = result.projectStatus
        console.log(`进度获取返回值`,result,JSON.stringify(result))
        this.data = Object.entries(result).map(item=>item[1]).filter(item=>item instanceof Object);
      }
    },
  },
  mounted() {},
  computed:{
    type(){
      return this.$route.query.type || '' 
    }
  },
  watch: {
    id() {
      if (this.id) {
        this.getDate();
      }
    },
  },
  created() {
    this.getId();
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-table td {
  color: #333;
}
/deep/ .el-table th {
  color: #666;
}

.w184 {
  width: 184px;
}
.w-r {
  width: calc(100% - 197px);
}

.color1 {
  color: #e6a23c;
}
.color2 {
  color: #0e459c;
}
.color3 {
  color: #f56c6c;
}
.color4 {
  color: #409eff;
}
.color5 {
  color: #67c23a;
}
.color6 {
  color: #fa6400;
}
.wrapper {
  padding-left: 83px;
  padding-right: 83px;
  padding-bottom: 15px;
  margin: 0 auto;
  min-width: 1200px;

  .section {
    background: #ffffff;
    padding: 0 20px;
    .title {
      font-size: 26px;
      color: #333333;
      line-height: 74px;
      position: relative;
      padding-left: 14px;
      margin-right: 14px;
      font-weight: 600;
      &::before {
        content: '';
        position: absolute;
        top: 28px;
        left: 0;
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
      }
    }
    .state-btn {
      // background: #67c23a;
      // border: 1px solid #67c23a;
      border-radius: 4px;
      height: 32px;
      width: 58px;
      text-align: center;
      line-height: 32px;
    }
    .state-btn1 {
      background-color: rgb(240, 249, 235);
      color: #6ec543;
      border: 1px solid #c1e6af;
    }
    .state-btn2 {
      background: rgb(236, 245, 255);
      color: #419bf9;
      border: 1px solid rgb(177, 215, 255);
    }
    .state-btn3 {
      background: rgb(254, 240, 240);
      color: rgb(245, 112, 112);
      border: 1px solid rgb(250, 195, 195);
    }
    .edit-btn {
      color: #cccccc;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      cursor: pointer;
      width: 96px;
    }
    .tmp-btn {
      margin-right: 16px;
      background: #135694;
      color: #ffffff;
      border-radius: 4px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      cursor: pointer;
      width: 96px;
    }
    .progress-box {
      padding: 23px 112px 35px 12px;

      .lable {
        width: 70px;
        margin-right: 54px;
        font-size: 14px;
        color: #333333;
        text-align: center;
      }
      .progress-content {
        padding-bottom: 32px;
        width: calc(100% - 124px);
        font-size: 12px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 3px;
          left: -30px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background: #cccccc;
        }
        &::after {
          content: '';
          position: absolute;
          top: 9px;
          left: -25px;
          width: 2px;
          height: 100%;
          background: #cccccc;
        }
        .info-box {
          margin-top: 6px;
          box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
          border-radius: 4px;
          padding: 0 30px;
          font-size: 14px;
          color: #666666;
          line-height: 19px;
          text-align: center;
          .name {
            color: #333333;
            font-weight: 600;
          }
          .text {
            margin-top: 6px;
          }
        }
        .success-box {
          height: 82px;
          .name {
            margin-right: 57px;
          }
        }
        .info-item {
          padding-top: 20px;
          padding-bottom: 17.5px;
        }
      }

      .no-line {
        &::after {
          width: 0;
        }
      }
    }
  }
}
</style>